/* toggle caption function */

const debug = false;

function toggleCaption() {
	let ss = sessionStorage;

	/* init state */

	if (ss.getItem('caption') === null) {
		if (debug) console.log('no key, setting up default');
		ss.setItem('caption', 'on');
	}

	if (ss.getItem('caption') === 'on') {
		if (debug) console.log('caption is already on');
		if (debug) console.log(ss);

		let grid = document.querySelector('.grid-main');
		grid.classList.add('has-caption');
	} else {
		if (debug) console.log('caption is already off');
		if (debug) console.log(ss);
	}

	/* set caption */

	let triggers = document.querySelectorAll('.js-toggle-caption');
	for (let i = 0; i < triggers.length; i++) {
		const trigger = triggers[i];

		trigger.addEventListener('click', function (event) {
			let grid = trigger.closest('.grid-main');

			/* set value */
			if (ss.getItem('caption') === 'on') {
				ss.setItem('caption', 'off');
				grid.classList.remove('has-caption');
			} else {
				ss.setItem('caption', 'on');
				grid.classList.add('has-caption');
			}
		});
	}
}

export { toggleCaption };
