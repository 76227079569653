/* synchronize scroll fonction */

function syncScroll() {
	let containers = document.querySelectorAll('.card__about');

	for (let i = 0; i < containers.length; i++) {
		const container = containers[i];
		container.addEventListener('scroll', function (event) {
			for (let i = 0; i < containers.length; i++) {
				const container = containers[i];
				container.scrollTop = this.scrollTop;
			}
		});
	}
}

export { syncScroll };
