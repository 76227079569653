import { enter, leave } from './components/animation.js';
import { toggleCaption } from './components/toggleCaption.js';
import { togglePanel } from './components/togglePanel.js';
import { syncScroll } from './components/syncScroll.js';

if (document.querySelector('.page--home')) {
	enter();
	leave();
	toggleCaption();
	togglePanel();
	syncScroll();
}
