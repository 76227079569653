/* toggle about panel function */

function togglePanel() {
	let buttons = document.querySelectorAll('.nav__group');
	for (let i = 0; i < buttons.length; i++) {
		const button = buttons[i];

		button.addEventListener('mouseover', function (event) {
			for (let i = 0; i < buttons.length; i++) {
				const button = buttons[i];
				if (!button.classList.contains('is-hovered')) {
					button.classList.add('is-hovered');
				}
			}
		});

		button.addEventListener('mouseleave', function (event) {
			for (let i = 0; i < buttons.length; i++) {
				const button = buttons[i];
				if (button.classList.contains('is-hovered')) {
					button.classList.remove('is-hovered');
				}
			}
		});
	}

	let openers = document.querySelectorAll('.js-open-about');
	for (let i = 0; i < openers.length; i++) {
		const open = openers[i];

		open.addEventListener('click', function (event) {
			let grid = open.closest('.grid-main');
			grid.classList.add('is-about');
		});
	}

	let closers = document.querySelectorAll('.js-close-about');
	for (let i = 0; i < closers.length; i++) {
		const close = closers[i];

		close.addEventListener('click', function (event) {
			let grid = close.closest('.grid-main');
			grid.classList.remove('is-about');
		});
	}
}

export { togglePanel };
