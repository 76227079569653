/* main animation functions */

const mainGrid = document.querySelector('.grid-main');
const shadowGrid = document.querySelector('.grid-shadow');
const className = 'is-visible';

function enter() {
	function load(event) {
		if (shadowGrid) {
			setTimeout(() => {
				shadowGrid.classList.add(className);
			}, 200); // mind the delay /!\
		}

		if (mainGrid) {
			setTimeout(() => {
				mainGrid.classList.add(className);
			}, 800); // mind the delay /!\
		}
	}

	/* load on event */
	window.addEventListener('load', load);
}

function leave() {
	function unload(event) {
		if (mainGrid) {
			mainGrid.classList.remove(className);
		}

		if (shadowGrid) {
			shadowGrid.classList.remove(className);
		}

		setTimeout(() => {
			location.reload(true);
		}, 400); // mind the delay /!\
	}

	/* unload on click */
	let triggers = document.querySelectorAll('.card__media');
	for (let i = 0; i < triggers.length; i++) {
		const trigger = triggers[i];
		trigger.addEventListener('click', unload);
	}
}

export { enter, leave };
